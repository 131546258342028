
.customer-information {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .add-btn-left {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
    }
}
